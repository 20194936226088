html,
body,
* {
  margin: 0;
  padding: 0;
}

html {
  background-color: #002b36;
  color: white;
  font-size: 14px;
}

.logo {
  padding: 1rem;
  font-size: 12px;
}

.about,
.tools {
  margin-bottom: 1rem;
}

.command {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.command svg {
  position: relative;
}

.shell {
  background-color: rgb(22, 22, 22);
  padding: 0.25rem;
  z-index: 100;
  color: white;
}

.location {
  display: flex;
  flex-direction: row;
  height: 24px;
  width: 3.5rem;
  justify-content: end;
  align-items: center;
  margin-left: -3rem;
  background-color: #268bd2;
}

.output {
  margin-left: 0.5rem;
  line-height: 2;
}

a {
  color: white;
}

@media screen and (max-width: 768px) {
  .logo {
    font-size: 10px;
  }
}
